<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="MessageSquareIcon"
        size="25"
      />Comunicazioni</h1>
    </b-card>
    <b-card class="p-2">
      <b-form ref="form">
        <b-row>
          <b-col
            class="mb-4 d-flex justify-content-center align-items-center"
            cols="12"
          >
            <feather-icon
              icon="MessageSquareIcon"
              size="25"
              class="mr-2"
            />
            <h2>Nuova Comunicazione</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="mb-2"
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Nome Comunicazione"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="newConversation.conversation_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="mb-2"
            cols="12"
          >
            <b-form-group
              label="Inserisci il Messaggio *"
              label-for="conversation_message"
            >
              <b-form-textarea
                id="conversation_message"
                v-model="conversation_message"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="mb-2"
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Scegli il tipo di Comunicazione *"
            >
              <b-form-radio-group
                id="radio-group-1"
                v-model="conversation_type"
                :options="type_options"
                :aria-describedby="ariaDescribedby"
                name="type-options"
                @input="selectType"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="conversation_type == 'users' && participantUsers && participantUsers.length > 0"
            class="mb-2"
            cols="12"
            md="4"
            lg="4"
          >
            <b-form-group
              label="Seleziona i Destinatari *"
              label-for="conversation_participants"
            >
              <v-select
                id="conversation_participants"
                :key="selectKey"
                v-model="newParticipant"
                :filterable="false"
                :options="participantUsersPaginated"
                @open="participantOnOpen"
                @close="onClose"
                @search="(query) => (search = query)"
                @input="addParticipant"
              >
                <template #list-footer>
                  <li
                    v-show="participantHasNextPage"
                    ref="participantLoad"
                    class="loader"
                  >
                    Carica più Utenti...
                  </li>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="conversation_type == 'users' && participantUsers && participantUsers.length > 0"
            class="mb-2 d-flex align-items-center"
            cols="12"
            md="1"
            lg="1"
          >
            <b-button
              variant="outline-primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              size="sm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="selectAll"
            >
              <span>Seleziona Tutti</span>
            </b-button>
          </b-col>
          <b-col
            v-if="conversation_type == 'departments' && userDepartments && userDepartments.length > 0"
            class="mb-2"
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-checkbox-group
              v-model="selectedDepartment"
              name="department-options"
              label="Scegli il Reparto di Destinazione *"
              stacked
            >
              <b-form-checkbox
                v-for="department in userDepartments"
                :key="department.value"
                name="department-options"
                :value="department.value"
              >{{ department.text }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
          <b-col
            v-if="conversation_type == 'departments' && userDepartments && userDepartments.length == 0"
            class="mb-2"
            cols="12"
            md="6"
            lg="6"
          >
            <h5 class="text-danger">
              L'utente selezionato non appartiene a nessun Reparto, cambiare selezione!
            </h5>
          </b-col>
          <b-col
            v-if="
              (conversation_type == 'users' && participantUsers && participantUsers.length > 0) ||
                conversation_type == 'departments'
            "
            class="mb-2"
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Allegati"
              label-for="attachments"
            >
              <b-form-file
                id="attachments"
                v-model="attachments"
                placeholder="Allega Documentazione scegliendo uno o più File o trascinandolo/i qui..."
                drop-placeholder="Trascina il/i File qui..."
                multiple
              >
                <template
                  slot="file-name"
                  slot-scope="{ names }"
                >
                  <b-badge variant="primary">
                    {{ names[0] }}
                  </b-badge>
                  <b-badge
                    v-if="names[1]"
                    variant="primary"
                    class="ml-1"
                  >
                    {{ names[1] }}
                  </b-badge>
                  <b-badge
                    v-if="names.length > 2"
                    variant="primary"
                    class="ml-1"
                  >
                    + {{ names.length - 2 }} Altri File
                  </b-badge>
                </template>
              </b-form-file>
            </b-form-group>
          </b-col>
          <b-col
            class="text-center"
            cols="12"
            md="6"
            lg="6"
          >
            <b-list-group :key="componentKey">
              <b-list-group-item
                v-for="(participant, key) in newParticipants"
                :key="participant.value"
                class="d-flex align-items-center justify-content-between"
              >
                <h6 class="mb-0">
                  {{ participant.label }}
                </h6>
                <b-button
                  variant="danger"
                  size="sm"
                  @click="deleteParticipant(key)"
                ><feather-icon
                  icon="XIcon"
                /></b-button>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="text-right mt-5"
            cols="12"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="sendNotification"
            >
              <b-spinner
                v-if="loading"
                variant="light"
                small
              />
              <span v-if="!loading">Invia Comunicazione</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormFile,
  BFormTextarea,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BImg,
  BSpinner,
  BBadge,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import userStoreModule from '@/views/apps/user/userStoreModule'
import departmentsStoreModule from '@/views/apps/departments/departmentsStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Client as ConversationsClient } from '@twilio/conversations'
import { getUserData } from '@/auth/utils'
import conversationsStoreModule from './conversationsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormFile,
    BFormTextarea,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BImg,
    BSpinner,
    BBadge,
    BFormCheckbox,
    BFormCheckboxGroup,

    vSelect,
  },
  setup() {
    const toast = useToast()
    const userData = getUserData()

    const CONVERSATIONS_APP_STORE_MODULE_NAME = 'app-conversations'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const DEPARTMENTS_APP_STORE_MODULE_NAME = 'app-departments'

    // Register module
    if (!store.hasModule(CONVERSATIONS_APP_STORE_MODULE_NAME)) {
      store.registerModule(CONVERSATIONS_APP_STORE_MODULE_NAME, conversationsStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }
    if (!store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTS_APP_STORE_MODULE_NAME, departmentsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONVERSATIONS_APP_STORE_MODULE_NAME)) { store.unregisterModule(CONVERSATIONS_APP_STORE_MODULE_NAME) }
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENTS_APP_STORE_MODULE_NAME)
    })

    const users = ref(null)
    const participantUsers = ref(null)
    const _users = []
    store
      .dispatch('app-user/fetchUsers', { per_page: 1000 })
      .then(response => {
        response.data[0].forEach((user, i) => {
          const _user = {
            label: `#${user.anagrafico_id} - ${user.display_name}`,
            value: user.id,
          }
          _users.push(_user)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    users.value = _users
    participantUsers.value = _users

    const userDepartments = ref(null)
    const _departments = []
    store
      .dispatch('app-departments/fetchDepartments')
      .then(response => {
        response.data[0].forEach((department, i) => {
          const _department = {
            text: `#${department.id} - ${department.name}`,
            value: department.id,
          }
          _departments.push(_department)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    userDepartments.value = _departments
    return {
      userData,
      users,
      loading: false,
      newConversation: {
        conversation_name: null,
        exclude_me: true,
        participants: [],
      },
      conversation_message: null,
      conversation_type: null,
      observer: null,
      limit: 10,
      search: '',
      participant_search: '',
      componentKey: 0,
      selectKey: 0,
      participantUsers,
      newParticipant: null,
      newParticipants: [],
      selectedAll: false,
      attachments: [],
      mediaUrl: null,
      userDepartments,
      selectedDepartment: null,
      isConnected: false,
      activeConversation: null,
      type_options: [
        { text: 'Utenti', value: 'users' },
        { text: 'Reparti', value: 'departments' },
      ],
    }
  },
  computed: {
    usersFiltered() {
      return this.users.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    },
    usersPaginated() {
      return this.usersFiltered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.usersPaginated.length < this.usersFiltered.length
    },
    participantUsersFiltered() {
      return this.participantUsers.filter(
        data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1,
      )
    },
    participantUsersPaginated() {
      return this.participantUsersFiltered.slice(0, this.limit)
    },
    participantHasNextPage() {
      return this.participantUsersPaginated.length < this.participantUsersFiltered.length
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    sendNotification() {
      const valid = this.validation()
      if (!valid) return
      this.loading = true
      const formData = new FormData()
      formData.append('title', this.newConversation.conversation_name)
      formData.append('sender_id', this.userData.id)
      formData.append('message', this.conversation_message)
      if (this.conversation_type == 'users') {
        if (this.newParticipants[0].value == 0) {
          formData.append('all', true)
        } else {
          const recipients = this.newParticipants.map(participant => participant.value)
          formData.append('recipients', recipients.toString())
        }
      }
      if (this.conversation_type == 'departments') formData.append('recipient_department_ids', this.selectedDepartment)
      if (this.attachments.length > 0) {
        for (let i = 0; i < this.attachments.length; i++) {
          const attachment = this.attachments[i]
          formData.append('files', attachment)
        }
      }
      store
        .dispatch('app-conversations/createConversation', formData)
        .then(result => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Comunicazione inviata con successo',
              icon: 'MessageSquareIcon',
              variant: 'success',
            },
          })
          this.emptyForm()
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
    },
    emptyForm() {
      this.newConversation = {
        conversation_name: null,
        exclude_me: true,
        participants: [],
      }
      this.conversation_message = null
      this.conversation_type = null
      this.selectedDepartment = []
      this.newParticipants = []
      this.componentKey++
      this.attachments = []
    },
    async selectSender() {
      this.conversation_type = null
      this.newParticipants = []
      this.selectedDepartment = []
      this.componentKey++
    },
    async selectType() {
      this.newParticipants = []
      this.selectedDepartment = []
      this.componentKey++
    },
    async refetchParticipantUsers(sender_id) {
      const participant_users = []
      const users = await store.dispatch('app-user/fetchUsers', {
        per_page: 1000,
        ignore_user_ids: sender_id,
      })
      for (let i = 0; i < users.data[0].length; i++) {
        const user = users.data[0][i]
        const participant_user = {
          label: `#${user.anagrafico_id} - ${user.display_name}`,
          value: user.id,
        }
        participant_users.push(participant_user)
      }
      this.selectKey++
      this.participantUsers = participant_users
    },
    getUserDepartments() {
      const _departments = []
      store
        .dispatch('app-departments/fetchDepartments')
        .then(response => {
          response.data[0].forEach((department, i) => {
            const _department = {
              text: `#${department.id} - ${department.name}`,
              value: department.twilio_conversation_sid,
            }
            _departments.push(_department)
          })
          this.userDepartments = _departments
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    addParticipant() {
      let duplicated = []
      if (this.newParticipants.length > 0) {
        duplicated = this.newParticipants.filter(el => el.value == this.newParticipant.value)
      }
      if (duplicated.length > 0) {
        this.newParticipant = null
        duplicated = []
        return this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Inserimento',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Utente già inserito come Partecipante della Conversazione',
          },
        })
      }
      if (this.newParticipants.length > 0 && this.newParticipants[0].value == 0) {
        this.newParticipants = []
        this.selectedAll = false
      }
      this.newParticipants.push(this.newParticipant)
      this.componentKey++
      this.newParticipant = null
    },
    deleteParticipant(key) {
      this.$delete(this.newParticipants, key)
      this.componentKey++
    },
    selectAll() {
      this.newParticipants = []
      const newParticipant = {
        label: 'Tutti gli Utenti selezionati',
        value: 0,
      }
      this.selectedAll = true
      this.newParticipants.push(newParticipant)
      this.componentKey++
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    async participantOnOpen() {
      if (this.participantHasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.participantLoad)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    validation() {
      if (!this.newConversation.conversation_name) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Titolo',
            icon: 'AlertTriangleIcon',
            text: 'Titolo della Comunicazione mancante.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.conversation_message) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Messaggio',
            icon: 'AlertTriangleIcon',
            text: 'Messaggio della Comunicazione mancante.',
            variant: 'danger',
          },
        })
        return false
      }
      if (!this.conversation_type) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Tipo',
            icon: 'AlertTriangleIcon',
            text: 'Selezionare il Tipo di Comunicazione per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (this.conversation_type === 'users' && (!this.newParticipants || this.newParticipants.length == 0)) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Utenti',
            icon: 'AlertTriangleIcon',
            text: 'Selezionare almeno un Utente destinatario per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      if (this.conversation_type === 'departments' && this.selectedDepartment.length === 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Reparti',
            icon: 'AlertTriangleIcon',
            text: 'Selezionare un Reparto destinatario per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
    // async sendConversation() {
    //   const valid = this.validation()
    //   if (!valid) return
    //   this.loading = true
    //   await this.createConversation(async () => {
    //     await this.sendMessage()
    //     this.loading = false
    //     this.emptyForm()
    //     this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         title: 'Comunicazione inviata con successo',
    //         icon: 'MessageSquareIcon',
    //         variant: 'success',
    //       },
    //     })
    //   })
    // },
    // async createConversation(callback) {
    //   await this.initConversationClient(async () => {
    //     if (!this.isConnected) return
    //     if (this.conversation_type == 'departments') {
    //       const sid = this.selectedDepartment
    //       this.activeConversation = await this.conversationsClient.getConversationBySid(sid)
    //     }
    //     if (this.conversation_type == 'users') {
    //       this.newConversation['participants'].push(this.conversation_sender.value)
    //       for (let i = 0; i < this.newParticipants.length; i++) {
    //         this.newConversation['participants'].push(this.newParticipants[i].value)
    //       }
    //       if (!this.newConversation['conversation_name'])
    //         this.newConversation['conversation_name'] = 'Comunicazione Backoffice'
    //       try {
    //         let result = await store.dispatch('app-conversations/createConversation', this.newConversation)
    //         const sid = result.data.twilio_conversation_sid
    //         this.activeConversation = await this.conversationsClient.getConversationBySid(sid)
    //       } catch (error) {
    //         if (error === 401) {
    //           localStorage.removeItem('userData')
    //           store.commit('user/updateUserData', null)
    //           this.$router.replace({ name: 'auth-login' }).then(() => {
    //             this.$toast({
    //               component: ToastificationContent,
    //               position: 'top-right',
    //               props: {
    //                 title: 'Sessione scaduta',
    //                 icon: 'AlertTriangleIcon',
    //                 text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
    //                 variant: 'danger',
    //               },
    //             })
    //           })
    //         }
    //       }
    //     }
    //     if (this.activeConversation) callback()
    //   })
    // },
    // async sendMessage() {
    //   await this.activeConversation.sendMessage(this.conversation_message)
    //   if (this.mediaFile) {
    //     const formData = new FormData()
    //     formData.append('file', this.mediaFile)
    //     await this.activeConversation.sendMessage(formData)
    //   }
    // },
    // async initConversationClient(callback) {
    //   const sid = this.conversation_sender.value
    //   const token = await store.dispatch('app-conversations/getUserTwilioToken', { sid })
    //   window.conversationsClient = ConversationsClient
    //   this.conversationsClient = new ConversationsClient(token.data.token)
    //   this.conversationsClient.on('connectionStateChanged', (state) => {
    //     switch (state) {
    //       case 'connected':
    //         this.isConnected = true
    //         callback()
    //         break
    //       case 'disconnecting':
    //         this.isConnected = false
    //         break
    //       case 'disconnected':
    //         this.isConnected = false
    //         break
    //       case 'denied':
    //         this.isConnected = false
    //         break
    //     }
    //   })
    // },
    // previewMedia() {
    //   if (this.mediaFile) this.mediaUrl = URL.createObjectURL(this.mediaFile)
    // },
    // removePreview() {
    //   URL.revokeObjectURL(this.mediaUrl)
    //   this.mediaUrl = null
    //   this.mediaFile = null
    // },
  },
}
</script>

<style lang="scss">
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Sfoglia';
}
</style>
<style lang="scss" scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
.send-file-icon:hover {
  cursor: pointer;
}
.badge:hover {
  cursor: pointer;
}
</style>
